<template>
  <div :id="event.uid" style="display: flex">
    <div class="shine yellow"></div>
    <div
      class="eventcard-wrap"
      :class="{'active-yellow': chosen}"
      @click.left="openDetailCard"
      @click.right="handlePopUpMenu($event)"
    >
      <div class="container-img">
        <div class="snapshot">
          <img v-lazy="event.snapshotUrl">
        </div>
      </div>
      <div class="container-info">
        <template>
          <div class="icons">
            <div v-if="event.motion === 1" class="icon moving">
              <img src="@/assets/icons/Moving.svg" alt="">
            </div>
            <div v-for="(cls, index) in filterObjClass" :key="index" class="icon">
              <img :src="iconMap[icons[cls - 1]]" alt="">
              
            </div>
            <div v-if="objClass.length > 5" class="icon">
              <img src="@/assets/icons/Dots_LightYellow.svg" alt="">
            </div>
          </div>
          <div class="time" :class="{ active: chosen }">
            <div>{{ detectTime }}</div>
          </div>
          <div class="info video-title">
            <TooltipElement :content="$t('history_fr_device'/*設備*/)" alwaysShow>
              <img src="@/assets/icons/Camera.svg" alt="">
            </TooltipElement>
            <TooltipElement :content="getDeviceTitleId(event.user.id)">
              <span>{{ getDeviceTitleId(event.user.id) }}</span>
            </TooltipElement>
          </div>

          <div class="info push-content">
            <TooltipElement :content="$t('history_fr_remark'/*備註*/)" alwaysShow>
              <img src="@/assets/icons/note.svg" alt="">
            </TooltipElement >
            <TooltipElement :content="event.notice">
              <span>{{ event.notice }}</span>
            </TooltipElement>
          </div>
          
        </template>
      </div>
      <div class="container-action">
        <TooltipElement :content="$t('more') /*更多*/" placement="bottom" alwaysShow>
          <div>
            <i class="el-icon-more info-icon" @click="handlePopUpMenu($event)"></i>
          </div>
        </TooltipElement>
      </div>
      <div class="pop-up-menu" v-if="popupOpen">
        <div
          class="option location"
          @click="showLocation($event)"
          @click.right="preventRightClickClose($event)"
        >
          <div><img src="@/assets/icons/userAccount.svg" alt="" /></div>
          <div>{{ $t('event_show_position') }}</div>
        </div>
        <div
          v-if="permissionV2.lprEventAccess > 0"
          class="option historyRecord"
          @click="showHistoryRecord($event)"
          @click.right="preventRightClickClose($event)"
        >
          <div><img src="@/assets/icons/clock.svg" alt="" /></div>
          <div>{{ $t('evnet_show_history') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { formatTime } from '@/utils/lib.js'

export default {
  name: 'OrEventCard',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue')
  },
  props: {
    event: Object
  },
  data() {
    return {
      popupOpen: false,
      chosen: false,
      clicks: 0,
      clickTimer: null,
      iconMap: {
        person: require('@/assets/icons/person.svg'),
        car: require('@/assets/icons/car.svg'),
        bicycle: require('@/assets/icons/bicycle.svg'),
        truck: require('@/assets/icons/truck.svg'),
        bus: require('@/assets/icons/bus.svg'),
      },
      icons: ['person', 'car', 'bicycle', 'truck', 'bus']
    }
  },
  computed: {
    ...mapState([
      'isDemo',
      'userList',
      'tagList',
      'selectedUsers',
      'shining',
      'eventCardDetailObj',
      'RightMenuOpen',
      'liveList',
      'codeBooks',
      'permissionV2'
    ]),
    ...mapGetters('account', ['getDeviceTitleId']),
    isSosEvent() {
      return this.event.uid.startsWith('sos')
    },
    isSosChasing() {
      return this.isSosEvent || this.event.chasing === 1
    },
    checkUrgent() {
      return this.event.chasing == 1 ||
        this.isSosEvent ||
        (this.event.chasingStartTime && this.event.chasingEndTime)
        ? true
        : false
    },
    shiningSts() {
      if (this.event.chasing == 1) {
        if (this.event.chasingStartTime && this.event.chasingEndTime) {
          return false
        }
        return this.shining
      }
      return false
    },
    missionIcon() {
      if (this.event.missionCode === 1)
        return require('@/assets/icons/SearchCar_S.svg')
      else if (this.event.missionCode === 2)
        return require('@/assets/icons/Ask_S.svg')
      else if (this.event.missionCode === 3)
        return require('@/assets/icons/Arrest_S.svg')
      else return require('@/assets/icons/SearchCar_S.svg')
    },
    bSelected() {
      return this.$store.state.selectedEvent.id === this.event.id
    },
    detectTime() {
      return formatTime(this.event.detectTime)
    },
    objClass() {
      return this.event.objects.map(obj => obj.class).filter((v, i, a) => a.indexOf(v) === i)
    },
    filterObjClass() {
      return this.objClass.length > 5 ? this.objClass.slice(0, 4) : this.objClass
    },
    sosStartTime() {
      return formatTime(this.event.startTime)
    },
    sosLiveSnapshotUrl() {
      // SOS若有Live，則有預覽圖片可以顯示
      if (this.event.uid.startsWith('sos')) {
        const live = this.liveList.find(
          (live) => live.id === this.event.userAccount
        )
        return live ? live.snapshotUrl : require('@/assets/icons/no-image.svg')
      } else return require('@/assets/icons/no-image.svg')
    },
    sosSnapshotObj() {
      return {
        src: this.event.snapshotUrl,
        error: this.sosLiveSnapshotUrl
      }
    },
    eventUserId() {
      return this.event.user.id
    }
  },
  watch: {
    eventCardDetailObj() {
      if (!('user' in this.eventCardDetailObj)) {
        this.chosen = false
      }
      if (
        this.event.detectTime == this.eventCardDetailObj.detectTime &&
        this.event.uid == this.eventCardDetailObj.uid
      ) {
        this.chosen = true
        const el = document.getElementById(this.event.uid)
        el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      } else {
        this.chosen = false
      }
    }
  },
  created() {
    this.$bus.$on('closePopUpMenu', () => {
      this.popupOpen = false
    })
  },
  mounted() {
    this.checkCardChosenSts()
  },
  beforeDestroy() {
    this.$bus.$off('closePopUpMenu')
  },
  methods: {
    ...mapMutations('history', ['updateEventPlate']),
    checkCardChosenSts() {
      // mounted 確認有沒有選中的event 有就讓那張卡呈現選中的樣式
      // 打開detail modal：bus emit 先讓所有eventcard回到初始狀態 再選中特定的卡
      // 關掉detail modal：bus emit 讓所有eventcard回到初始狀態
      if (Object.keys(this.eventCardDetailObj).length == 0) {
        this.chosen = false
        return
      }
      this.chosen = this.eventCardDetailObj.uid === this.event.uid ? true : false
    },
    preventRightClickClose(e) {
      e.stopPropagation()
    },
    showLocation(e) {
      //console.log(e)
      e.stopPropagation()
      this.popupOpen = false
      //console.log(this.event.user.id)
      let element = document.getElementById(this.eventUserId)
      element.scrollIntoView({ behavior: 'smooth' })
      // 顯示帳號位置 要同步把左邊的該user也勾選起來
      let idx = this.selectedUsers.findIndex(
        (user) => user.id == this.eventUserId
      )
      if (idx >= 0) this.$store.commit('setTrackUser', this.selectedUsers[idx])
    },
    showHistoryRecord(e) {
      e.stopPropagation()
      this.popupOpen = false
      // this.updateEventPlate(this.event.triggered[0].content)
      this.$router.push('/history')
    },
    handlePopUpMenu(e) {
      e.stopPropagation()
      e.preventDefault()
      this.$bus.$emit('closePopUpMenu')
      this.popupOpen = !this.popupOpen
    },
    openDetailCard() {
      this.clicks++
      if (this.clicks === 1) {
        // 2秒後重設 clicks = 0, 避免使用者短時間快速點擊
        this.clickTimer = setTimeout(() => {
          this.clicks = 0
        }, 2000)

        // 打開跳窗
        // 新舊事件跳窗型態不同 就要判斷哪個開哪個關 相同就單純更新eventDetailObj和liveVideoUrl即可
        this.$bus.$emit('closePopUpMenu')
        this.$store.dispatch('handleEventModal', this.event)
      }
    },
    syncUserTree() {
      // 點擊right bar追車事件時 要同步把左邊的該user也勾選起來
      let idx = this.selectedUsers.findIndex(
        (user) => user.id == this.eventUserId
      )
      if (idx >= 0) this.$store.commit('setTrackUser', this.selectedUsers[idx])
      else {
        // 點擊未勾選裝置的追車事件，自動將其勾選(加入selectedUsers --> reloadGroupUserTree)，並將其設為trackUser
        let clickUser = this.userList.find(
          (item) => item.id === this.eventUserId
        )
        if (clickUser) {
          // 須將資料處理成與selectedUser, trackUser一樣的格式
          let user = {
            id: clickUser.id,
            name: clickUser.video.title,
            groupId: clickUser.groupId,
            label: clickUser.video.title + ' (' + clickUser.id + ')'
          }
          this.$store.commit('addSelectedUser', user)
          this.$store.commit('setTrackUser', user)
        }
      }
      this.$bus.$emit('panToTrackUser') // 地圖移動到TrackUser最新的位置
    },
    clickEvent() {
      this.$store.commit('updateSelectedEvent', this.event)
      if (this.event.chasing == 1) {
        this.$store.state.showVideoStatus = true
      } else {
        // 跳窗
        this.$store.state.showVideoStatus = false
        this.$store.state.showEventDetail = true
      }
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.eventcard-wrap {
  display: flex;
  width: 363px;
  background-color: #19223b;
  border: 1px solid #4a5c78;
  border-left: none;
  border-radius: 0px 5px 5px 0px;
  padding: 8px 0px 8px 8px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  position: relative;
}

.active-yellow {
  background-color: #384c83;
  border-color: #ffc600;
  border-width: 3px 3px 3px 0;
}

.active-red {
  background-color: #426393;
  border-color: #f94144;
  border-width: 3px 3px 3px 0;
}

.eventcard-wrap.active {
  background-color: #426393;
}

.eventcard-wrap:first-child {
  margin-top: 20px;
}

.shine {
  border-left: 10px solid #4a5c78;
  margin-bottom: 10px;
  border-radius: 5px 0px 0px 5px;
  visibility: visible;
}

.red {
  border-left: 10px solid #f94144;
}

.yellow {
  border-left: 10px solid #ffc600;
}

.gray {
  border-left: 10px solid #4a5c78;
}

.none {
  border-left: 10px solid #f9414466;
}

.photo,
.snapshot {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff1a;
  border-radius: 3px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
  }
  img[lazy=error] {
    width: 60%;
    height: 60%;
  }
}

.photo {
  width: 96px;
  height: 96px;
  margin-bottom: 4px;
  
}

.snapshot {
  width: 96px;
  height: 54px;
  position: relative;
}

.snapshot > .camera {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 28px;
  height: 28px;
}

.container-info {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: calc(100% - 126px);
  padding: 0 4px 0 8px;
}

.icons {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: px2rem(24);
  line-height: 40px;
  font-weight: 500;
  color: #FFE99F;

  .icon {
    width: 32px;
    height: 32px;
    &.moving {
      width: 48px;
      height: 32px;
    }
  }
  
  img {
    width: 100%;
    height: 100%;
    @include filter_FFE99F;
  }
}

.sos-chasing {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f94144;
  border: 4px solid #f94144;
  border-radius: 8px;
  margin-bottom: 4px;
  padding: 3px 0;
  color: #ffffff;
  animation: bgchange 1.5s infinite;
}

.sos-chasing img {
  margin-right: 8px;
}

.sos-chasing-title {
  font-size: 34px;
  line-height: 46px;
}

@keyframes bgchange {
  0% {
    background-color: #f94144;
  }
  50% {
    background-color: #f9414433;
  }
  100% {
    background-color: #f94144;
  }
}

.plate {
  position: relative;
  display: flex;
  align-items: center;
}

.plate-code {
  font-size: px2rem(24);
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.5px;
  color: #ffe99f;
}

.plate-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}


.time {
  & > div {
    display: inline-block;
    height: 25px;
    background: #4A5C78;
    border-radius: 17px;
    padding: 0px 12px;
    font-size: px2rem(14);
    line-height: 25px; 
    letter-spacing: 0.5px;
  }
  &.active > div {
    background: #282942;
  }
}

.info {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  img {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
  span {
    font-size: px2rem(16);
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #ffffff;
  }
}

.video-title {
  width: 100%;
  span {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.separater {
  width: 100%;
  height: 1px;
  background: #ffffff33;
  margin: 6px 0;
}

.urgent {
  left: -2px;
}

.tags {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
}

.tags span {
  height: 24px;
  background: #ffffff33;
  padding: 0 12px;
  border-radius: 12px;
  margin-right: 8px;
  white-space: nowrap;
}

// .push-message {
//   margin-bottom: 4px;
//   font-size: 20px;
//   letter-spacing: 1.5px;
//   color: #ffffff;
// }

.message {
  display: flex;
  align-items: center;
}

.message img {
  margin-right: 8px;
  vertical-align: bottom;
}

.push-content {
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  word-break: break-all;
}

.push-content img {
  position: relative;
  margin-right: 8px;
  top: 3px;
}

.push-content span {
  /* width: 100%; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.container-action {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.mis-judgment {
  opacity: 0.2;
  &.error {
    opacity: 1;
  }
}

.mis-judgment img {
  vertical-align: middle;
}

.pop-up-menu {
  background-color: #224974;
  padding: 2px 5px 2px 1px;
  gap: 5px;
  box-sizing: border-box;
  border-radius: 3px;
  position: absolute;
  top: 13px;
  right: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.option {
  display: flex;
  gap: 10px;
  line-height: 20px;
  color: #fff;
  font-size: 13px;
  letter-spacing: 0.8px;
  height: 50%;
  align-items: center;
  box-sizing: border-box;
  padding-left: 5px;
}

.option img {
  width: 15px;
  height: 15px;
  margin-top: 5px;
}

.location:hover {
  background-color: #577590;
  border-radius: 3px 3px 0px 0px;
}

.historyRecord:hover {
  background-color: #577590;
  border-radius: 0px 0px 3px 3px;
}

.note {
  line-height: 24px;
}

.info-icon {
  transform: rotate(90deg);
  color: #ffffff;
  cursor: pointer;
}
</style>
